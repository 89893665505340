/*
 * See: https://fonts.google.com/license/googlerestricted
 */
/* cyrillic */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Kwp5MKg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Nwp5MKg.woff2)
    format("woff2");
  unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Bwp5MKg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Awp5MKg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/googlesans/v27/4UaGrENHsxJlGDuGo1OIlL3Owp4.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@media (prefers-color-scheme: dark) {
  body {
    background: #2f3234 !important;
    color: #f6f8fa !important;
  }
  #header {
    background: #1e2122 !important;
    border-bottom: 1px solid #1d1f21 !important;
  }
  #uptime {
    background-color: #40444a !important;
    border: 1px solid #474b52 !important;
  }
  #uptime .item {
    border-color: #575c65 !important;
  }
  #uptime .meta .name {
    color: #f6f8fa !important;
  }
  #uptime .item:hover {
    background-color: #484a40 !important;
  }
}
* {
  border: 0;
  margin: 0;
  padding: 0;
  outline: none;
  list-style: outside none;
  box-sizing: border-box;
}

body {
  background: #f6f8fa url(./assets/background.png) center top;
  color: #3f4347;
  font-size: 12px;
  font-family: "Google Sans", sans-serif;
  line-height: 1;
  position: relative;
}

a {
  text-decoration: none;
  color: inherit;
}

.container {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 10px;
}

#app {
  padding-top: 75px;
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid #e3e4e6;
  z-index: 10;
}

#header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
}

#header .logo {
  font-size: 18px;
  font-weight: bold;
  color: #319848;
}

#header .navi {
  font-size: 14px;
}

#header .navi a {
  color: #838b90;
  transition: color ease-out 300ms;
  margin-left: 15px;
}

#header .navi a:hover {
  color: #4b9b5d;
}

#uptime {
  background: #fff;
  border: 1px solid #e3e4e6;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

#uptime .item {
  padding: 18px 20px;
  border-bottom: 1px solid #e7e8e9;
}

#uptime .item:last-child {
  border-bottom: none;
}

#uptime .loading {
  background: url(./assets/loading.svg) center center / 40px 40px no-repeat;
  height: 100px;
}

#uptime .item:hover {
  background-color: #fffdf5;
}

#uptime .meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

#uptime .meta .info {
  display: flex;
  align-items: center;
}

#uptime .meta .name {
  font-size: 16px;
  line-height: 20px;
  color: #5a606b;
}

#uptime .meta .link {
  background: url(./assets/link.svg) center center / 100% 100% no-repeat;
  width: 13px;
  height: 13px;
  margin-left: 6px;
  opacity: 0.6;
  text-indent: -99999px;
  transition: opacity ease-out 300ms;
}

#uptime .meta .link:hover {
  opacity: 1;
}

#uptime .meta .status {
  background: left center / 14px auto no-repeat;
  padding-left: 20px;
  font-size: 14px;
}

#uptime .meta .status.ok {
  background-image: url(./assets/success.svg);
  color: #28a745;
}

#uptime .meta .status.down {
  background-image: url(./assets/fail.svg);
  color: #d9534f;
}

#uptime .meta .status.unknow {
  background-image: url(./assets/question.svg);
  color: #6a737d;
}

#uptime .timeline {
  height: 35px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

#uptime .timeline i {
  flex-grow: 1;
  margin: 0 1px;
  cursor: pointer;
}

#uptime .timeline i:hover {
  opacity: 0.6;
}

#uptime .timeline i.ok {
  background: #9fd8ac;
}

#uptime .timeline i.none {
  background: #f3f3f3;
}

#uptime .timeline i.down {
  background: #f29571;
}

#uptime .tooltip {
  font-size: 12px !important;
  padding: 6px 9px !important;
  border-radius: 4px !important;
}

#uptime .foot {
  display: flex;
  justify-content: space-between;
  line-height: 15px;
  color: #999fa6;
}

#footer {
  text-align: center;
  font-size: 12px;
  line-height: 1.8;
  color: #999fa6;
  padding: 20px 0px;
}

#footer a {
  color: #58ad6a;
  font-weight: bold;
}
